
  import { defineComponent, onMounted, onUnmounted } from "vue";
  import { useStore } from "vuex";
  import { Actions } from "../../../store/enums/StoreEnums";
  import { getIllustrationsPath } from "../../../core/helpers/assets";
  import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
  import { reactive } from "vue";
  import { ref } from "vue";
  import { DateTimeUtils } from "@/latipay/utils/DateTimeUtils";
  import { reinitializeComponents } from "@/core/plugins/keenthemes";
  import { getAccountsRequest } from "@/latipay/apis/services/AccountsService";
  import { EnumsConstant } from "@/store/enums/constants";
  import { AccountSourcesResponse } from "@/latipay/apis/models/accountSource/AccountSourcesResponse";


  export default defineComponent({
    name: "accounts",
    components: {},
    setup() {
      const store = useStore();


      const sourceName = ref<string | null>(null);
      const type = ref<string | null>(null);
      const country = ref<string | null>(null);
      const currency = ref<string | null>(null);
      const accountName = ref<string | null>(null);
      const accountNumber = ref<string | null>(null);
      const sort = ref<string | null>(null);
      const pageNumber = ref(1);
      const pageSize = ref(20);

      const loading = ref(false);
      const countries = EnumsConstant.CountryCode;
      const currencies = EnumsConstant.CurrencyCode;
      const accountSources: AccountSourcesResponse[] = [];

      const formInline = reactive({
        type: "",
        country: "",
        sourceName: "",
        currency: "",
        accountName: "",
        accountNumber: ""

      });

      const {
        accountsRef,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        getAccounts
      } = getAccountsRequest();


      const getData = async () => {
        loading.value = true;

        // await updateQueries();
        await getAccounts(
          formInline.type,
          formInline.country,
          formInline.sourceName,
          formInline.currency,
          formInline.accountName,
          formInline.accountNumber,

          sort.value,
          pageNumber.value,
          pageSize.value
        );
        reinitializeComponents();

        loading.value = false;

        console.log(accountsRef);
      };

      const onSubmit = () => {
        console.log("submit!");
      };


      onMounted(async () => {
        // setCurrentPageTitle("Users");
        await getData();
      });

      const updatePageSize = async (val: number) => {
        console.log(`${val} items per page`);
        pageSize.value = val;
        await getData();
      };
      const updatePageNumber = async (val: number) => {
        console.log(`current page: ${val}`);
        pageNumber.value = val;
        await getData();
      };

      return {
        getIllustrationsPath,
        formInline,
        onSubmit,
        countries,

        getData,
        loading,
        updatePageSize,
        updatePageNumber,
        accountsRef,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        type,
        country,
        sourceName,
        currency,
        accountName,
        accountNumber,
        accountSources,
        currencies,
        DateTimeUtils,

      };
    },

    methods: {
      async resetFormFields() {

        (this.$refs["searchForm"] as any).resetFields();

        await this.getData();

      },
      async onSubmitSearchForm() {
        await this.getData();
      }

    }
  });
